import React, { Component } from 'react'
import { StaticQuery, graphql } from 'gatsby'
import { Logo } from '../../../theme/LogoFooter'
import GatsbyLink from '../GatsbyLink'
import RenderContent from '../RenderContent'
import { SocialLinks } from '../SocialLinks'
import './Footer.scss'
import { decodeEntities } from '../../utils/helpers'
const isClient = typeof window !== 'undefined'

class Footer extends Component {
  sendEmail(event, index) {
    event.preventDefault()
    const { data } = this.props
    const { email } = data.siteSettings.options.locations[index]
    window.location = `mailto:${decodeEntities(email)}`
  }
  render() {
    const { data, location = {} } = this.props
    const {
      legalNavigation,
      footerNavigation,
      primaryNavigation,
      siteSettings,
      wordpressWpSettings,
    } = data
    const { items: legalNav } = legalNavigation
    const { items: footerNav } = footerNavigation
    const { items: primaryNav } = primaryNavigation
    const {
      footerTagline,
      navigationInFooter,
      contactDetailsInFooter,
      newsletterText,
    } = siteSettings.options
    const currentYear = new Date().getFullYear()
    return (
      <>
        <footer>
          <SocialLinks />
          <div className="footer-main">
            <div className="wrap">
              <div className="inner">
                <div className="logos">
                  <Logo />
                  {footerTagline && (
                    <RenderContent
                      className="tagline"
                      content={footerTagline}
                    />
                  )}
                </div>

                {siteSettings.options.locations &&
                  siteSettings.options.locations.map((location, index) => (
                    <div className="location" key={index}>
                      <span className="h3">{location.locationName}</span>
                      <p
                        className="address"
                        dangerouslySetInnerHTML={{
                          __html: location.addressDisplay
                            .split(', ')
                            .join('</br>'),
                        }}
                      />
                      {location.phone && (
                        <p>
                          <strong>
                            <a
                              href={`tel:${location.phone.replace(
                                /[^A-Z0-9]/gi,
                                ''
                              )}`}
                              className="control-call"
                              aria-label="Call Now"
                            >
                              {location.phone}
                            </a>
                          </strong>
                        </p>
                      )}
                      {location.email && (
                        <p>
                          <a
                            href="#"
                            onClick={event => this.sendEmail(event, index)}
                          >
                            <span
                              dangerouslySetInnerHTML={{
                                __html: location.email.replace(
                                  '&#64;',
                                  '</br>&#64;'
                                ),
                              }}
                            />
                          </a>
                        </p>
                      )}
                    </div>
                  ))}

                <div className="grid-filler"></div>

                <div>
                  <span className="h3">More Info</span>
                  <ul className="footer-nav">
                    {footerNav &&
                      footerNav.map((item, index) => (
                        <li key={index}>
                          <GatsbyLink
                            to={item.url}
                            aria-label={item.title}
                            decode={true}
                          >
                            {item.title}
                          </GatsbyLink>
                        </li>
                      ))}
                  </ul>
                </div>

                <div>
                  <span className="h3">Products</span>
                  <ul className="footer-nav">
                    {primaryNav &&
                      primaryNav.map((item, index) => (
                        <li key={index}>
                          <GatsbyLink
                            to={item.url}
                            aria-label={item.title}
                            decode={true}
                          >
                            {item.title}
                          </GatsbyLink>
                        </li>
                      ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="disclaimers">
            <div className="copyright">
              ©{currentYear} <strong>{wordpressWpSettings.title}</strong>, All
              rights reserved
            </div>

            <ul className="navigation">
              {legalNav.map((item, index) => (
                <li key={index}>
                  <GatsbyLink
                    to={item.url}
                    aria-label={item.title}
                    decode={true}
                  >
                    {item.title}
                  </GatsbyLink>
                </li>
              ))}
            </ul>
          </div>
        </footer>
      </>
    )
  }
}

export default props => (
  <StaticQuery
    query={graphql`
      query {
        legalNavigation: wordpressWpApiMenusMenusItems(
          slug: { eq: "legal-navigation" }
        ) {
          items {
            title
            url
            classes
            slug: object_slug
          }
        }
        footerNavigation: wordpressWpApiMenusMenusItems(
          slug: { eq: "footer-navigation" }
        ) {
          items {
            title
            url
            classes
            slug: object_slug
            children: wordpress_children {
              title
              url
              classes
              slug: object_slug
            }
          }
        }
        primaryNavigation: wordpressWpApiMenusMenusItems(
          slug: { eq: "primary-navigation" }
        ) {
          items {
            title
            url
            classes
            slug: object_slug
            object_id
            target
          }
        }
        siteSettings: wordpressAcfOptions(options: {}) {
          options {
            locations {
              locationName
              addressDisplay
              email
              phone
            }
            newsletterText
          }
        }
        wordpressWpSettings {
          title
        }
      }
    `}
    render={data => <Footer data={data} {...props} />}
  />
)
