import React from 'react';
import GatsbyLink from '../src/components/GatsbyLink';

export const Logo = props => {
  return (
    <GatsbyLink to="/" className="logo-footer" aria-label="Home">
    <svg width={67} height={88} viewBox="0 0 67 88" {...props}>
      <path
        d="M18.515 61.158l7.724-29.657h14.503l7.724 29.657h-29.95zm30.57-41.571H17.897L.171 87.807h11.402l3.84-14.735H51.57l3.838 14.734h11.4L49.086 19.587zm-32.006-7.67h32.85V.27h-32.85v11.648z"
        fill="#000"
        fillRule="evenodd"
      />
    </svg>
    </GatsbyLink>
  );
}
